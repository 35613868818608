<template>
  <div class="locationMap">
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          width="570"
          height="310"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=56, Sector 44, Gurugram, Haryana 122003&t=&z=10&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>
    <div class="contactUsAddress">
      <div>
        <h1>Address:</h1>
        <p>
          56, Sector 44, <br />
          Gurugraom, Haryana <br />
          122003
        </p>
      </div>
      <div>
        <h1>Phone:</h1>
        <p>
          +91 8178992075
        </p>
      </div>
      <div>
        <h1>Email</h1>
        <p>durgvijay.sahani@connaissant.com</p>
      </div>
      <div>
        <h1>Working Hours</h1>
        <p>
          Monday to Friday: 10:00 AM - 6:30 PM <br />Sunday: Closed <br />Even
          Saturdays Closed
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
